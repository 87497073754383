    // Helpers
    var parseHTML = function (str) {
        const tmp = document.implementation.createHTMLDocument("tmp");
        tmp.body.innerHTML = str;
        return tmp.body.children;
    };

    var _si = function (selector, context = document, returnNull = false) {
        const tmp = context.querySelector(selector);
        return tmp ? tmp : returnNull ? null : _c("div");
    };
    var _s = function (selector, context = document) {
        return Array.apply(null, context.querySelectorAll(selector));
    };

    var _c = function (elm) {
        return document.createElement(elm);
    };

    var _ael = function (sel, ev, callback) {
        document.addEventListener(ev, (e) => {
        if (e.target === document) {
            return;
        }
        const that = e.target.closest(sel);
        if (that) {
            callback(that, e);
        }
        });
    };
    // END HELPERS
    const html = _si("html");
    const body = document.body;
    let navOpen = false;
    let cart = [];
    let totalPrice = 0;
    let allItems = 0;
    let totalPriceFooter = 0;

    window.addEventListener("load", function () {
        body.style.opacity = "1";
        body.classList.add("loaded");
    });

    function updateCart(item){
        if(cart.length > 0){
            const itemExistIndex = cart.findIndex(element => {
                if (element.productId === item.productId) {
                  return true;
                }
                return false;
            });
            if(item.orderedCount === 0){
                cart.splice(itemExistIndex, 1)
            }else{
                if(itemExistIndex !== -1){
                    cart[itemExistIndex].orderedCount = item.orderedCount;
                }else{
                    cart.push(item);
                }
            }
        }else{
            cart.push(item);
        }
        console.log(cart);
        //update cart counter and total price in footer.
        allItems = 0;
        totalPriceFooter = 0;
        for (let i = 0; i < cart.length; i++) {
            const element = cart[i];
            allItems = allItems += element.orderedCount;
            totalPriceFooter = totalPriceFooter += (element.price*element.orderedCount);
        }
        _si(".counter-items").innerHTML = allItems;
        _si(".total").innerHTML = totalPriceFooter;
    }
    function buildObject(elm){
        const input = elm;
    }
    _ael(".type-title", "click", (elm, e) => {
        e.preventDefault();
        elm.classList.toggle("type-open");
    });
    _ael(".dish-title", "click", (elm, e) => {
        e.preventDefault();
        elm.classList.toggle("dish-count-open");
    });
    _ael(".increase", "click", (elm, e) => {
        e.preventDefault();
        let input = elm.previousElementSibling;
        let value = parseInt(input.value, 10);
        let dishPrice = parseInt(input.getAttribute("data-price"));
        let dishType = input.getAttribute("data-dish");
        
        value = isNaN(value) ? 0 : value;
        value++;
        input.value = value;

        let newObject = {
            productId: parseInt(input.getAttribute("data-dishid")),
            name: dishType,
            price: dishPrice,
            orderedCount: value
        }
        updateCart(newObject);
    });
    _ael(".decrease", "click", (elm, e) => {
        e.preventDefault();
        let input = elm.nextElementSibling;
        let value = parseInt(input.value, 10);
        let dishPrice = parseInt(input.getAttribute("data-price"));
        let dishType = input.getAttribute("data-dish");
        if(isNaN(value) || value == 0){
            return;
        }
        value = isNaN(value) ? 0 : value;
        value--;
        input.value = value;

        let newObject = {
            productId: parseInt(input.getAttribute("data-dishid")),
            name: dishType,
            price: dishPrice,
            orderedCount: value
        }
        updateCart(newObject);
    });
    _ael(".open-cart", "click", (elm, e) => {
        e.preventDefault();
        _si(".footer-cart").classList.toggle("open");
        body.classList.toggle("open");
        const ulList = _si(".cart-list");
        if(cart.length > 0){
            ulList.innerHTML = "";
            _si("#to-checkout").disabled = false;
            for (let j = 0; j < cart.length; j++) {
                const element = cart[j];
                //List item
                let liItem = _c("div");
                liItem.classList.add("cart-item")
                //Product name
                let divName = _c("div");
                let nameNode = document.createTextNode(cart[j].name);
                divName.classList.add("product-name");
                divName.appendChild(nameNode);

                let divWrapper = _c("div");
                divWrapper.classList.add("product-wrapper");
                //Quantity ordered
                let divCount = _c("div");
                let countNode = document.createTextNode(cart[j].orderedCount);
                divCount.classList.add("product-quantity");
                divCount.appendChild(countNode);

                //Product price
                let divPrice = _c("div");
                let priceNode = document.createTextNode(cart[j].price + " kr");
                divPrice.classList.add("product-price");
                divPrice.appendChild(priceNode);

                //Product price
                let totalTextPrice = parseInt(cart[j].price)*parseInt(cart[j].orderedCount);
                let divTotalPrice = _c("div");
                let priceTotalNode = document.createTextNode(totalTextPrice + " kr");
                divTotalPrice.classList.add("product-totalPrice");
                divTotalPrice.appendChild(priceTotalNode);

                //Adding elements to li-parent.
                liItem.appendChild(divName);
                divWrapper.appendChild(divCount);
                divWrapper.appendChild(divPrice);
                divWrapper.appendChild(divTotalPrice);
                liItem.appendChild(divWrapper);

                //Adding li to ul.
                ulList.appendChild(liItem);
                
            }
        }else{
            ulList.innerHTML = "Ingen produkter er lagt til."
            _si("#to-checkout").disabled = true;
        }
    });
    _ael("#to-checkout", "click", (elm, e) => {
        e.preventDefault();
        _si(".checkout-form").classList.add("open-checkout");
        const orderField = _si(".hidden textarea");
        console.log(orderField);
        let string = "";
        for (let f = 0; f < cart.length; f++) {
            const element = cart[f];
            let totalTextPrice = parseInt(element.price)*parseInt(element.orderedCount);
            string = string += element.name + " - Antall: " + element.orderedCount + " - Total: " + totalTextPrice + "kr\n";
            
        }
        orderField.innerHTML = string;
    });
    _ael("#close-checkoutform", "click", (elm, e) => {
        e.preventDefault();
        _si(".checkout-form").classList.remove("open-checkout");
        
    });
   